import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Rule details`}</h2>
    <p>{`The Primer CSS tooltip set using the `}<inlineCode parentName="p">{`.tooltipped`}</inlineCode>{` class has been deprecated. There are major accessibility concerns with using this deprecated tooltip so please take action.`}</p>
    <p>{`Depending on your usecase, there are two potential migration paths.`}</p>
    <h2>{`Option 1: Don't use a tooltip.`}</h2>
    <p>{`Tooltips are not visible by default and can easily go unnoticed, so they should only be used as a last resort, and should never be used to convey critical information.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`.tooltipped`}</inlineCode>{` is being set on a non-interactive element (e.g. `}<inlineCode parentName="p">{`<span>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<p>`}</inlineCode>{`), please consider an alternative. There is no way to make a tooltip fully accessible on a non-interactive element. See `}<a parentName="p" {...{
        "href": "https://primer.style/components/tooltip#alternatives-to-tooltips"
      }}>{`Tooltip alternatives`}</a>{` or consult a designer.`}</p>
    <h2>{`Option 2: Migrate to the more accessible PVC tooltip`}</h2>
    <p>{`If your tooltip is appropriately set on an interactive element, you can migrate to the latest `}<a parentName="p" {...{
        "href": "https://primer.style/components/tooltip/rails/alpha"
      }}>{`Tooltip component`}</a>{` which has been implemented with accessibility considerations.`}</p>
    <h2>{`Example scenarios`}</h2>
    <h3>{`Scenario 1`}</h3>
    <p>{`Flagged code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<span aria-label="Mona Lisa" class="tooltipped tooltipped-s"> Mona Lisa </span>
`}</code></pre>
    <p>{`In this above example, we can get rid of the tooltip because it redundantly repeats the text content, like the following`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<span>Mona Lisa</span>
`}</code></pre>
    <h3>{`Scenario 2`}</h3>
    <p>{`Flagged code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button aria-label="This action is irreversible" class="tooltipped tooltipped-n">Submit</button>
`}</code></pre>
    <p>{`In this above example, the information that is conveyed using the tooltip is critical so we should not be using a tooltip to convey it. Update the design to always persist the text.`}</p>
    <h3>{`Scenario 3`}</h3>
    <p>{`Flagged code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a
  aria-label="A set of guidelines, principles, and patterns for designing and building UI at GitHub."
  class="tooltipped tooltipped-s"
  href="primer.style"
>
  Primer
</a>
`}</code></pre>
    <p>{`In this above example, the information conveyed in this tooltip isn't necessarily critical but supplements the link. If we decide to permanently persist it,we can update the markup like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a aria-describedby href="https://primer.style/"> Primer </a>
<p id="primer-link-help">A set of guidelines, principles, and patterns for designing and building UI at GitHub.</p>
`}</code></pre>
    <p>{`A tooltip is also a viable option in this scenario. We can render an accessible tooltip by using the slot of the Link component and setting the tooltip type to `}<inlineCode parentName="p">{`:description`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Beta::Link.new(href: "https://primer.style/", id: "primer-link")) do |component| %>
  <% component.with_tooltip(
    type: :description,
    text: "A set of guidelines, principles, and patterns for designing and building UI at GitHub."
  ) %>

  Primer
<% end %>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      